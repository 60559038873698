import request from '@/common/axios';

// 代理商奖励规则列表
export function fetchBonusRuleList(data) {
    return request({
        url: '/platform',
        method: 'POST',
        data: {
            "iReqCode": 2905,
            oReqData: data
        }
    });
}

// 添加代理商奖励规则
export function addBonusRule(data) {
    return request({
        url: '/platform',
        method: 'POST',
        data: {
            "iReqCode": 2900,
            oReqData: {
                ...data
            }
        }
    });
}

// 修改代理商奖励规则
export function updateBonusRule(data) {
    return request({
        url: '/platform',
        method: 'POST',
        data: {
            "iReqCode": 2903,
            oReqData: {
                ...data
            }
        }
    });
}
