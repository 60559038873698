<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <!-- <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col> -->
    </el-row>
    <div>
      <el-button class="but" type="primary" @click="showAddBonusRule" v-auth="this.per.AWARD_ADD"
        >添加奖励设置</el-button
      >
    </div>
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="规则" prop="ruleName"></el-table-column>

      <el-table-column
        label="奖励金额(元)"
        prop="goodsMarketPrice"
        align="right"
      >
        <template #default="scope">
          <span>{{ scope.row.money / 100 }}</span>
        </template>
      </el-table-column>

      <el-table-column label="奖励类型" prop="type">
        <template #default="scope">
          <eb-badge
            :list="this.const.BONUS_RULE_TYPE"
            :target="scope.row.type"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="状态" prop="status">
        <template #default="scope">
          <eb-badge
            :list="this.const.RULE_STATUS"
            :target="scope.row.status"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <span class="option option-primary" @click="showEditModal(scope.row)" v-auth="this.per.AWARD_UPDATA">
            编辑
          </span>
          <span
            class="option option-primary"
            v-auth="this.per.AWARD_DISABLED"
            @click="
              operationBonusRule(scope.row, RULE_STATUS_CODE.ABLE)
            "
          >
            启用
          </span>
          <span
            class="option option-primary"
            v-auth="this.per.AWARD_DISABLED"
            @click="
              operationBonusRule(scope.row, RULE_STATUS_CODE.DISABLED)
            "
          >
            停用
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageNo"
      @current-change="getList"
    ></el-pagination>

    <EditModal
      :modalData="modalData"
      :modalType="modalType"
      :ref="MODAL_KEY.EDIT_MODAL"
      @submit="submit"
    ></EditModal>
  </div>
</template>

<script>
import { fetchBonusRuleList, addBonusRule, updateBonusRule } from "@/api/bonus";
import EditModal from "./component/EditModal";
import {RULE_STATUS_CODE} from '@/const/index'
export default {
  components: { EditModal },
  data() {
    return {
      RULE_STATUS_CODE:RULE_STATUS_CODE,
      dataSource: [],
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {
        money: -1,
        ruleName: "",
        type: -1,
        status: -1,
        info: "",
      }, // 查询条件

      modalData: {},

      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
        MAP_MODAL: "MAP_MODAL",
      },
      total: 0,
    };
  },
  methods: {
    showAddBonusRule() {
      this.modalData = {};
      this.modalType = "add";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    showEditModal(row) {
      this.modalData = this.deepClone(row);
      this.modalData.money = this.util.formatNum(this.modalData.money / 100);
      this.modalType = "edit";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    // 修改 规则状态
    operationBonusRule(data,status) {
      console.log(data,status)
      let params = this.deepClone(data);
      delete params.updateAt;
      delete params.createAt;
      params.status = status;

      updateBonusRule(params).then((res) => {
        this.$message.success("修改成功");
        this.getList();
      });
    },

    submit(data) {
      let request = updateBonusRule;
      let msg = "修改成功";
      if (this.validatenull(data.id)) {
        request = addBonusRule;
        msg = "添加成功";
      }

      request(data).then((res) => {
        this.$message.success(msg);
        this.getList();
        this.$refs[this.MODAL_KEY.EDIT_MODAL].closeModal();
      });
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchBonusRuleList({ query: this.query, ...this.pages }).then(
        (res) => {
          this.dataSource = res.data.data.records;
          this.total = res.data.data.total;
        }
      );
    },
  },

  mounted() {
    this.getList();
  },
};
</script>
